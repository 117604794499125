<script setup lang="ts">
import { ref } from "vue";
import RefundFormHeader from "@/components/RefundFormHeader.vue";
import MultipleUpload from "@/components/dashboard/request/MultipleUpload.vue";
import TextInput from "@/components/dashboard/request/TextInput.vue";
import { storeToRefs } from "pinia";
import { useRefundForm } from "../composables/useRefundForm";
import { useRefundStore } from "../stores/refundStore";

import AlertOutline from "@/components/icons/AlertOutline.vue";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/outline";

const refundStore = useRefundStore();
const { submitForm, errors, submissionSuccess } = useRefundForm();
const { clientDefaultValues: client, errorLoadingForm } =
  storeToRefs(refundStore);

const hasAttemptedSubmit = ref(false);

const handleSubmit = async () => {
  try {
    await submitForm();
  } catch (error) {
    console.log(error);
  }

  hasAttemptedSubmit.value = true;
};
</script>
<template>
  <RefundFormHeader />

  <div
    v-if="errorLoadingForm"
    class="w-full relative min-h-[90vh] flex flex-col justify-center flex-grow"
  >
    <div class="flex flex-col text-center">
      <XCircleIcon class="h-20 text-orangeRapihogar mb-6" />
      <p class="font-montserrat text-lg font-bold">¡Ooops!</p>
      <p class="font-montserrat text-lg">
        Hubo un error al cargar el formulario
      </p>
    </div>
  </div>

  <template v-else>
    <div
      v-if="!submissionSuccess"
      class="min-h-full flex-col flex-grow justify-start py-10 px-10 md:px-[120px] gap-4"
    >
      <div class="flex flex-col mb-4 md:mb-8">
        <span class="font-montserrat text-[15px] md:text-lg font-bold"
          >¡Hola!
        </span>
        <span class="font-montserrat text-[15px] md:text-lg">
          Completá los datos para realizar el reintegro</span
        >
      </div>

      <main>
        <form
          class="flex flex-col gap-6 md:gap-8"
          @submit.prevent="handleSubmit"
        >
          <section>
            <div
              class="border-section py-4 2xl:py-9 md:py-8 px-4 md:px-8 2xl:px-12 grid grid-cols-1 md:grid-cols-3 gap-x-9 gap-y-4"
            >
              <p class="text-xs md:text-sm font-bold">
                Titular Póliza: <span>{{ client?.fullName }}</span>
              </p>
              <p class="text-xs md:text-sm font-bold">
                Póliza N°: <span>{{ client?.policy }}</span>
              </p>
              <p class="text-xs md:text-sm font-bold">
                Compañía: <span>{{ client?.company }}</span>
              </p>
              <p class="text-xs md:text-sm font-bold">
                ID del pedido: <span>{{ client?.pedidoId }}</span>
              </p>
              <p class="text-xs md:text-sm font-bold">
                Servicio: <span>{{ client?.service }}</span>
              </p>
            </div>
          </section>

          <section>
            <h4 class="title text-base md:text-xl mb-1 md:mb-2">
              Completá con los datos del titular de la póliza
            </h4>
            <p class="text-sm">
              Recordá que el CUIL y el CBU deben corresponder al titular de la
              póliza.
            </p>
            <div
              class="border-section py-4 2xl:py-9 px-4 md:px-6 2xl:px-12 grid grid-cols-1 md:grid-cols-2 gap-x-9 gap-y-6 mt-4"
            >
              <TextInput
                name="nombre_cliente"
                label="Nombre y apellido"
                read-only
                placeholder=""
                type="text"
              />
              <TextInput
                label="Email"
                name="email_cliente"
                placeholder="ejemplo@gmail.com"
                type="email"
              />
              <TextInput
                name="cuil_cliente"
                label="N° de CUIL"
                placeholder="00-12345678-0 (Completar con guiones)"
                type="text"
              />
              <TextInput
                name="cbu_cliente"
                label="N° de CBU"
                placeholder="01234567891234567891234"
                type="text"
              />
            </div>
          </section>

          <section>
            <h4 class="title text-base md:text-xl mb-1 md:mb-4">
              Completá los datos del servicio
            </h4>
            <div class="border-section py-4 2xl:py-8 px-4 md:px-8 2xl:px-12">
              <div class="flex flex-col pt-4 gap-3 md:gap-8">
                <div
                  class="grid grid-cols-1 md:grid-cols-2 gap-x-9 gap-y-6 gap-3 md:gap-8"
                >
                  <TextInput
                    name="nombre_prestador"
                    label="Prestador"
                    placeholder="Empresa o nombre y apellido del prestador"
                    type="text"
                  />
                  <TextInput
                    name="cuit_prestador"
                    label="N° de CUIT"
                    placeholder="00-12345678-0 (Completar con guiones)"
                    type="text"
                  />
                  <TextInput
                    name="total_servicio"
                    label="TOTAL"
                    placeholder="Monto Total"
                    type="text"
                  />
                </div>
                <div class="wrapper">
                  <MultipleUpload
                    name="imagen_factura"
                    labelButton="Subí la factura o ticket"
                    form-type="REINTEGRO"
                  />
                </div>
              </div>
            </div>
          </section>

          <template v-if="client?.mustUploadDocumentation">
            <section class="md:mt-9">
              <h4 class="title text-base md:text-xl mb-1 md:mb-4">
                Subí una foto del ANTES de realizar el servicio
              </h4>
              <div class="wrapper">
                <MultipleUpload
                  name="fotos_antes"
                  labelButton="Subir fotos del ANTES"
                  form-type="REINTEGRO"
                />
              </div>
            </section>

            <section>
              <h4 class="title text-base md:text-xl mb-1 md:mb-4">
                Subí una foto del DESPUÉS de realizar el servicio
              </h4>
              <div class="wrapper">
                <MultipleUpload
                  name="fotos_despues"
                  labelButton="Subir fotos del DESPUÉS"
                  form-type="REINTEGRO"
                />
              </div>
            </section>
          </template>

          <div
            v-if="hasAttemptedSubmit && errors && Object.keys(errors).length"
            class="flex gap-x-4 border border-[#D23232] bg-[#d232321f] p-4 rounded-md mt-0"
          >
            <div>
              <AlertOutline />
            </div>
            <div class="text-[#D23232] font-normal text-xs leading-6">
              <p>
                Algunos datos están incompletos o son incorrectos. Por favor,
                verificá los errores marcados.
              </p>
            </div>
          </div>

          <button
            class="ml-auto btn md:text-base text-white font-bold md:w-[145px] bg-blueRapihogar btn-transition hover:bg-blue-700"
            type="submit"
          >
            Enviar
          </button>
        </form>
      </main>
    </div>

    <div
      v-else
      class="w-full relative min-h-[90vh] flex flex-col justify-center flex-grow"
    >
      <div class="flex flex-col text-center">
        <CheckCircleIcon class="h-20 text-greenStatus-200 mb-6" />
        <p class="font-montserrat text-lg font-bold">¡Listo!</p>
        <p class="font-montserrat text-lg">
          El formulario se envió correctamente. Revisá tu
          <br class="hidden md:block" />
          correo electrónico para verificar los datos cargados.
        </p>
      </div>
    </div>
  </template>
</template>

<style scoped></style>
